<template>
  <div class="loader-container">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: 'mainPreloader'
}
</script>

<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed to cover the full viewport height */
}

.loader {
  width: 70px;
  aspect-ratio: 1;
  background:
    radial-gradient(farthest-side, #ffa516 90%, #0000) center/16px 16px,
    radial-gradient(farthest-side, green 90%, #0000) bottom/12px 12px;
  background-repeat: no-repeat;
  animation: l17 1s infinite linear;
  position: relative;
}

.loader::before {
  content: "";
  position: absolute;
  width: 8px;
  aspect-ratio: 1;
  inset: auto 0 16px;
  margin: auto;
  background: #ccc;
  border-radius: 50%;
  transform-origin: 50% calc(100% + 10px);
  animation: inherit;
  animation-duration: 0.5s;
}

@keyframes l17 {
  100% {
    transform: rotate(1turn);
  }
}
</style>
