<template>
  <div class="about-us">
    <aboutSummary />
    <!-- <ourTeam /> -->
    <ourValues />
    <ourAchivement />

  </div>
</template>

<script>
import aboutSummary from '../components/aboutUs/aboutSummary.vue'
// import ourTeam from '../components/aboutUs/ourTeam.vue'
import ourValues from '../components/aboutUs/ourValues.vue'
import ourAchivement from '../components/aboutUs/ourAchivement.vue'
import { useMeta } from 'vue-meta'

export default {
  name: "aboutUs",
  components: {
    aboutSummary,
    // ourTeam,
    ourValues,
    ourAchivement
  },
  setup() {
    useMeta({
      title: 'About Stan Consultancy - Expert Business Consulting Services',
      meta: [
        { name: 'description', content: 'Learn more about Stan Consultancy and our expert business consulting services. Discover how we can help your business grow and succeed.' },
        { property: 'og:title', content: 'About Stan Consultancy - Expert Business Consulting Services' },
        { property: 'og:description', content: 'Learn more about Stan Consultancy and our expert business consulting services. Discover how we can help your business grow and succeed.' },
        { name: 'twitter:title', content: 'About Stan Consultancy - Expert Business Consulting Services' },
        { name: 'twitter:description', content: 'Learn more about Stan Consultancy and our expert business consulting services. Discover how we can help your business grow and succeed.' }
      ]
    });
  },

};
</script>