<template>
    <div class="section values p-5">
        <div class="container">
          <div class="row">
            <div class="col-md-8 offset-md-2 text-center">
              <h2 class="section-title mb-4">About Stan Consultancy</h2>
              <p class="section-subtitle">
                Welcome to Stan Consultancy, where we specialize in delivering
                cutting-edge solutions tailored to your business needs.
              </p>
              <p>
                Founded with a vision to transform businesses through innovation
                and strategic consulting, Stan Consultancy has been a trusted
                partner for numerous organizations globally.
              </p>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
export default {
name:'aboutSummary'
}
</script>

<style scoped>
.card {
  border: none; /* Remove default card border */
}
.card-title {
  font-size: 1.5rem;
}
.card-text {
  font-size: 1.1rem;
}
h2{
  color: #b1ad00 !important;
}
.values{
  background-color: rgba(8, 39, 8, 0.295);
}
</style>