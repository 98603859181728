<template>
        <div class="section bg-light p-5">
            <div class="container">
              <h2 class="section-title text-center mb-4">Our Achievements</h2>
              <div class="row">
                <div class="col-md-6">
                  <div class="card mb-4">
                    <div class="card-body">
                      <h5 class="card-title">Industry Recognition</h5>
                      <p class="card-text">
                        We have been recognized by industry experts for our
                        outstanding contributions to business consultancy.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card mb-4">
                    <div class="card-body">
                      <h5 class="card-title">Client Success Stories</h5>
                      <p class="card-text">
                        Our clients' success stories are a testament to our
                        dedication and expertise in delivering results.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
</template>

<script>
export default {
name:'ourAchivement'
}
</script>

<style scoped>

.card {
  border: none; /* Remove default card border */
}
.card-title {
  font-size: 1.5rem;
}
.card-text {
  font-size: 1.1rem;
}
h2{
  color: #b1ad00 !important;
}
</style>