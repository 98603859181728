<template>
    <div class="section values p-5">
        <div class="container">
            <h2 class="section-title text-center mb-4">Our Values</h2>
            <div class="row">
                <div class="col-md-4">
                    <div class="card mb-4">
                        <div class="card-body text-center">
                            <i class="bi bi-people icon1"></i>
                            <h5 class="card-title mt-3">Customer-Centric</h5>
                            <p class="card-text">
                                We prioritize our clients' needs and strive to exceed their
                                expectations.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card mb-4">
                        <div class="card-body text-center">
                            <i class="bi bi-lightbulb icon1"></i>
                            <h5 class="card-title mt-3">Innovation</h5>
                            <p class="card-text">
                                We embrace creativity and innovation to deliver unique
                                solutions.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card mb-4">
                        <div class="card-body text-center">
                            <i class="bi bi-shield-check icon1"></i>
                            <h5 class="card-title mt-3">Integrity</h5>
                            <p class="card-text">
                                We uphold the highest ethical standards in all our
                                interactions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
name:'ourValues'
}
</script>

<style scoped>
.card {
  border: none; /* Remove default card border */
}
.card-title {
  font-size: 1.5rem;
}
.card-text {
  font-size: 1.1rem;
}
h2{
  color: #b1ad00 !important;
}
.values{
    background-color: rgb(8, 39, 8);
}
</style>