<template>
    <div class="container">
      <div class="row d-flex justify-content-center align-items-center" style="height: 80vh;">
        <div class="col-md-8 text-center">
          <img :src="image" alt="404 Image" style="max-width: 100%; height: auto;">
          <h1 class="display-4">Oops!</h1>
          <p class="lead">We couldn't find the page you're looking for.</p>
          <router-link to="/" class="btn btn-primary">Go to Homepage</router-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound',
    data(){
        return{
            image:'../assets/images/404.png'
        }
    }
  }
  </script>
  
  <style scoped>
  /* Add custom styles here */
  .container {
    padding-top: 20px;
  }
  
  .display-4 {
    font-size: 3.5rem;
    font-weight: 500;
  }
  
  .lead {
    font-size: 1.25rem;
    font-weight: 300;
    margin-bottom: 30px;
  }
  
  .btn-primary {
    color: #fff;
    background-color: #b1ad00 ;
    border-color: #b1ad00 ;
    padding: 10px 20px;
    font-size: 1.25rem;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: rgb(8, 39, 8);
  }
  </style>
  