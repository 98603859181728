import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-icons/font/bootstrap-icons.css'
import "swiffy-slider/css"
import 'vue-toast-notification/dist/theme-bootstrap.css';



import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import { swiffyslider } from 'swiffy-slider'
import store from './store/store.js'
import { createMetaManager } from 'vue-meta'

  
window.swiffyslider = swiffyslider;
window.addEventListener("load", () => {
    window.swiffyslider.init();
});



const app = createApp(App)


app.use(createMetaManager())
app.use(store)
app.use(swiffyslider)
app.use(router)
app.mount('#app')
