import { createRouter, createWebHistory } from "vue-router";
import homePage from '../view/homePage.vue'
import singleService from '../view/singleService.vue'
import NotFound from '../view/NotFound.vue'
import contactUs from '../view/contactUs.vue'
import aboutUs from '../view/aboutUs.vue'






const router = createRouter({
    history: createWebHistory(),
    routes:[

        {path:'/', component: homePage},
        {path:'/service/:title', component: singleService},
        {path:'/:catchAll(.*)', component: NotFound},
        {path:'/contact', component: contactUs},
        {path:'/about-us', component: aboutUs}


    ]
})

export default router
