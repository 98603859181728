<template>
<nav class="navbar navbar-expand-lg" data-bs-theme="dark" style="background:rgb(8, 39, 8);">
  <div class="container-fluid">
    <img @click.prevent="goHome" class="logo" :src="logo" alt="Logo" loading="lazy">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarColor02">
      <ul class="navbar-nav me-auto">
        <li class="nav-item">
          <router-link class="nav-link active" to="/">Home
            <span class="visually-hidden">(current)</span>
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdZRdVo3Ib0PV_kE3bObw_8ZGKHfxFHbO2rujFrLaRCSprmlg/viewform">Book Appointment</a>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/contact">Contact Us</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/about-us">About</router-link>
        </li>
      </ul>
      <div class="d-flex">
        <a href="https://www.facebook.com/people/Stan-Llm/pfbid02yjL36nhuTQ8qkDyqYNQUtou45kzBrqkdko1puWq8Hra6f1py3LkzSQPiVAjRFxHul/" class="text-light me-sm-2"><span><i class="bi bi-facebook icon2"></i></span></a>
        <a href="https://x.com/stan_llm" class="text-light me-sm-2 icon2"><span><i class="bi bi-twitter-x"></i></span></a>
        <a href="" class="text-light me-sm-2"><span><i class="bi bi-youtube icon2"></i></span></a>
        <a href="https://www.linkedin.com/in/stan-llm-543938277/" class="text-light me-sm-2"><span><i class="bi bi-linkedin icon2"></i></span></a>


      </div>
    </div>
  </div>
</nav>
    
</template>

<script>
 export default {
    name:'navBar',
    data(){
        return{
            logo:'../assets/images/stanlogo.png'
        }
    },
    methods:{
      goHome(){
        this.$router.push('/')
      }
    }
 }
</script>

<style scoped>
.logo-container {
  text-align: center; 
}

.logo {
  max-width: 100%;
  height: auto; 
  cursor: pointer;
}

@media (max-width: 767px) {
  .logo {
    width: 30%; 
  }
}

@media (min-width: 768px) {
  .logo {
    width: 10%;
}
}
</style>