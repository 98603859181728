<template>
    <footer class="footer bg-dark text-white">
      <div class="container p-4">
        <div class="row">
          <div class="col-md-4">
            <h5>Contact Us</h5>
            <address>
              <strong>Stan Consultancy</strong><br />
              KNUST - Kumasi<br />
              Ghana<br />
              +233 247839941
            </address>
            <div class="social-links mt-3">
                <a href="https://www.facebook.com/people/Stan-Llm/pfbid02yjL36nhuTQ8qkDyqYNQUtou45kzBrqkdko1puWq8Hra6f1py3LkzSQPiVAjRFxHul/" class="text-light me-sm-2"><span><i class="bi bi-facebook icon2"></i></span></a>
                <a href="https://x.com/stan_llm" class="text-light me-sm-2 icon2"><span><i class="bi bi-twitter-x"></i></span></a>
                <a href="" class="text-light me-sm-2"><span><i class="bi bi-youtube icon2"></i></span></a>
                <a href="https://www.linkedin.com/in/stan-llm-543938277/" class="text-light me-sm-2"><span><i class="bi bi-linkedin icon2"></i></span></a>
            </div>
          </div>
          <div class="col-md-4">
            <h5>Quick Links</h5>
            <ul class="list-unstyled">
              <li><router-link to="/">Home</router-link></li>
              <li><router-link to="/about-us">About Us</router-link></li>
              <li><router-link to="/contact">Contact</router-link></li>
              <li><a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdZRdVo3Ib0PV_kE3bObw_8ZGKHfxFHbO2rujFrLaRCSprmlg/viewform">Book Us</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h5>Subscribe to Our Newsletter</h5>
            <form @submit.prevent="subscribe" class="form-inline mt-3">
              <div class="form-group flex-grow-1">
                <input type="email" class="form-control mr-2" placeholder="Enter your email" required />
              </div>
              <button type="submit" disabled class="btn mt-3">Subscribe</button>
            </form>
          </div>
        </div>
      </div>
      <div class="bottom-footer text-center py-3">
        <div class="container">
          <p class="mb-0">&copy; 2024 Stan Consultancy. All rights reserved. Built with <a href="#" target="_blank" class="text-warning">LightFingers <span><i class="bi bi-fingerprint text-warning icon2"> </i></span></a></p>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: "pageFooter",
    data() {
      return {
        email: ""
      };
    },
   
  };
  </script>
  
  <style scoped>
  .footer {
    padding: 60px 0;
  }
  
  .footer h5 {
    color: #b1ad00;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .footer address {
    font-size: 0.95rem;
    color: #cccccc;
  }
  
  .social-links .social-link {
    display: inline-block;
    font-size: 1.5rem;
    color: #ffffff;
    margin-right: 10px;
  }
  
  .social-links .social-link:hover {
    color: #b1ad00;
  }
  
  .bottom-footer {
    background-color: #343a40;
    color: #ffffff;
    font-size: 0.9rem;
  }
  a{
    color: white;
  }
  button{
    background-color: #b1ad00;
  }
  </style>
  