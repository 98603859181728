<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? content : '' }}</template>
  </metainfo>  
  <navBar />
  <mainPreloader v-if="isLoading" />
  <router-view />
  <pageFooter />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navBar from './components/navBar.vue';
import mainPreloader from './components/mainPreloader.vue';
import pageFooter from './components/pageFooter.vue';

export default {
  name: 'App',
  components: {
    navBar,
    mainPreloader,
    pageFooter
  },
  computed: {
    ...mapState({
      isLoading: state => state.isLoading
    })
  },
  methods: {
    ...mapActions(['fetchSingleService', 'fetchSlider', 'fetchServices'])
  },
  mounted() {
    this.fetchSingleService(),
      this.fetchSlider(),
      this.fetchServices()
  }

}
</script>

<style>
.isTitle1 {
  color: #b1ad00;
}

.icon1 {
  font-size: 2rem;
}

.icon1:hover {
  color: #b1ad00;
  cursor: pointer;
}

.icon2 {
  margin: 5px;
  font-size: 1.4rem;
}

.icon2:hover {
  color: #b1ad00;
  cursor: pointer;
}



.loader {
  width: 70px;
  aspect-ratio: 1;
  background:
    radial-gradient(farthest-side, #ffa516 90%, #0000) center/16px 16px,
    radial-gradient(farthest-side, green 90%, #0000) bottom/12px 12px;
  background-repeat: no-repeat;
  animation: l17 1s infinite linear;
  position: relative;
}

.loader::before {
  content: "";
  position: absolute;
  width: 8px;
  aspect-ratio: 1;
  inset: auto 0 16px;
  margin: auto;
  background: #ccc;
  border-radius: 50%;
  transform-origin: 50% calc(100% + 10px);
  animation: inherit;
  animation-duration: 0.5s;
}

@keyframes l17 {
  100% {
    transform: rotate(1turn)
  }
}
</style>
