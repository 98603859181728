import { createStore } from "vuex";

import axios from "axios";
import { useToast } from "vue-toast-notification";
const $toast = useToast();

const store = createStore({
  state() {
    return {
      slider: [],
      services: [],
      singleService: [],
      isLoading: false,
    };
  },
  mutations: {
    fetchSlider(state, data) {
      state.slider = data;
    },
    fetchServices(state, data) {
      state.services = data;
    },
    fetchSingleService(state, data) {
      state.singleService = data;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  getters: {
    getSingleService: (state) => (params) => {
      return state.singleService.find((item) => item.title === params);
    },
  },
  actions: {
    async fetchSlider({ commit }) {
      commit("setLoading", true);
      const response = await axios.get("../assets/json/sliderData.json");
      const data = response.data.sliderItems;
      commit("fetchSlider", data);
      commit("setLoading", false);
    },
    async fetchServices({ commit }) {
      commit("setLoading", true);
      const response = await axios.get("../assets/json/services.json");
      const data = response.data.services;
      commit("fetchServices", data);
      commit("setLoading", false);
    },
    async fetchSingleService({ commit }) {
      commit("setLoading", true);
      const response = await axios.get("../assets/json/single-service.json");
      const data = response.data.services;
      commit("fetchSingleService", data);
      commit("setLoading", false);
    },
    async sendFormData({ commit }, user) {
      commit("setLoading", true);
      try {
        const response = await axios.post(
          "https://stanconsultancy.com/PHPmailer/mailer.php",
          user
        );
        commit("setLoading", false);
        if (response.request.status === 200 && response.data.success === true) {
          $toast.open({
            message: "Thanks! Message Sent",
            type: "success",
            position: "top-right",
          });
        } else {
          $toast.open({
            message: "Opps! Something went wrong",
            type: "error",
            position: "top-right",
          });
        }
        console.log(response);
      } catch (error) {
        console.error("Error sending form data:", error);
        $toast.open({
          message: "An error occurred while sending the email",
          type: "error",
          position: "top-right",
        });
      }
    },
  },
});
export default store;
