<template>
    <div class="container mt-5">
        <div class="row justify-content-center">
            <div class="col-lg-6 mb-4">
                <div class="card text-light p-4" style="background: rgb(8, 39, 8);">
                    <div class="card-body">
                        <h5 class="card-title">Contact Us</h5>
                        <p class="card-text">Welcome to Stan Consultancy! We're here to assist you with all your
                            business needs.</p>
                        <ul class="list-unstyled">
                            <li><i class="bi bi-check-circle-fill  icon1"></i> Expertise: Our team comprises seasoned
                                professionals.</li>
                            <li><i class="bi bi-check-circle-fill  icon1"></i> Custom Solutions: Tailored to your
                                specific challenges.</li>
                            <li><i class="bi bi-check-circle-fill  icon1"></i> Client-Centric Approach: Your
                                satisfaction is our priority.</li>
                        </ul>
                        <p>Ready to take your business to the next level? Contact us today!</p>
                        <address>
                            <strong><i class="bi bi-geo-alt-fill"></i> Stan Consultancy</strong><br>
                            <i class="bi bi-geo-alt"></i> KNUST - Kumasi<br>
                            <i class="bi bi-geo-alt"></i> Ghana<br>
                            <i class="bi bi-phone"></i> +233 247839941
                        </address>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 mb-4">
                <div class="card">
                    <div class="card-body" style="background: #b1ad00;">
                        <Form @submit="onSubmit" :validation-schema="validateForm"
                            class="form bg-light rounded shadow py-3 px-3">
                            <h4>Connect With US Now! </h4>
                            <div class="form-group mb-3">
                                <Field class="form-control" placeholder="Full Name" name="fullName" type="text" />
                                <ErrorMessage :class="Error" name="fullName" />
                            </div>
                            <div class="form-group mb-3">
                                <Field class="form-control" placeholder="Email" name="email" type="email" />
                                <ErrorMessage :class="Error" name="email" />

                            </div>

                            <div class="form-group mb-3">
                                <Field class="form-select" name="subject" as="select">
                                    <option disabled>Choose Subject</option>
                                    <option value="Family Life Management">Family Life Management</option>
                                    <option value="Business Growth">Business Growth</option>
                                    <option value="Company Management">Company Management</option>
                                    <option value="Legal Services">Legal Services</option>
                                    <option value="Personal Life Management">Personal Life Management</option>
                                </Field>
                                <ErrorMessage :class="Error" name="subject" />

                            </div>

                            <div class="form-group mb-3">
                                <Field class="form-control" placeholder="Phone Number" name="phone" type="tel" />
                                <ErrorMessage :class="Error" name="phone" />

                            </div>
                            <div class="form-group mb-3">
                                <Field name="message" class="form-control" as="textarea" rows="5" placeholder="Message">
                                </Field>
                                <ErrorMessage :class="Error" name="message" />

                            </div>
                            <button type="submit" class="btn ">Submit</button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { mapActions } from 'vuex';
import * as yup from 'yup';
import { useMeta } from 'vue-meta'

export default {
    name: 'contactUs',
    setup() {
        useMeta({
            title: 'Contact Stan Consultancy - Get in Touch with Us Today',
            meta: [
                { name: 'description', content: 'Contact Stan Consultancy for expert business consulting services. Fill out the form or call us to discuss your business needs.' },
                { property: 'og:title', content: 'Contact Stan Consultancy - Get in Touch with Us Today' },
                { property: 'og:description', content: 'Contact Stan Consultancy for expert business consulting services. Fill out the form or call us to discuss your business needs.' },
                { name: 'twitter:title', content: 'Contact Stan Consultancy - Get in Touch with Us Today' },
                { name: 'twitter:description', content: 'Contact Stan Consultancy for expert business consulting services. Fill out the form or call us to discuss your business needs.' }
            ]
        });
    },
    data() {
        return {
            Error: 'error',
            validateForm: yup.object({
                fullName: yup.string().required(),
                phone: yup.number().required(),
                email: yup.string().email().required(),
                subject: yup.string().required(),
                message: yup.string().required(),
            })
        }
    },
    components: {
        Form,
        Field,
        ErrorMessage
    },
    methods: {
        ...mapActions(['sendFormData']),

        onSubmit(values) {
            this.sendFormData(values)
        },

    },
}
</script>

<style scoped>
.form-group {
    padding: 10px;
}

.error {
    color: red;
}

button {
    background: #b1ad00 !important;
}
</style>