<template>
    <div class="container-">
        <div class="container-fluid section-hero ">
            <h4 class="text-light fs-1 hero-title">{{ service.title }}</h4>
        </div>
        <div class="row m-4">
            <div class="bg-light mb-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                <p><span class="fs-2 isTitle1">{{ service.title }}</span> <span><i
                            class="bi bi-heart-arrow icon1"></i></span> {{ service.description }}</p>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 ">
                <ul class="bg-light px-4 py-3 rounded bulletingSection ">
                    <div class="container-fluid section-title ">
                        <h4 class=" fs-1 isTitle1 text-center">Why CHoose Us</h4>
                        <hr>
                    </div>
                    <li>
                        <h6><span><i class="bi bi-check-circle-fill icon1"></i></span> Tailored Strategic
                            Solutions</h6>

                    </li>
                    <hr>
                    <li>
                        <h6><span><i class="bi bi-check-circle-fill icon1"></i></span> Continuous Improvement
                        </h6>

                    </li>
                    <hr>
                    <li>
                        <h6><span><i class="bi bi-check-circle-fill icon1"></i></span> Leadership Development
                        </h6>

                    </li>
                    <hr>
                    <li>
                        <h6><span><i class="bi bi-check-circle-fill icon1"></i></span> Risk Management
                            Strategies</h6>

                    </li>
                    <hr>
                    <li>
                        <h6><span><i class="bi bi-check-circle-fill icon1"></i></span> Leadership Development
                        </h6>

                    </li>
                    <hr>
                    <div>
                        <button class="btn get-in-touch-btn w-100">Get In Touch</button>
                    </div>
                </ul>
            </div>


        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useMeta } from 'vue-meta'

export default {
    name: 'singleService',
    setup() {
        useMeta({
            title: 'Services || Stan Consultancy',
            meta: [
                { name: 'description', content: 'Learn more about Services at Stan Consultancy. Discover how our expert consultants can help your business with this service.' },
                { property: 'og:title', content: 'Service Name - Stan Consultancy' },
                { property: 'og:description', content: 'Learn more about Services at Stan Consultancy. Discover how our expert consultants can help your business with this service.' },
                { name: 'twitter:title', content: 'Service Name - Stan Consultancy' },
                { name: 'twitter:description', content: 'Learn more about Services at Stan Consultancy. Discover how our expert consultants can help your business with this service.' }
            ]
        });
    },

    created() {
        this.fetchSingleService()
    },

    computed: {
        service() {
            const params = this.$route.params.title
            return this.getSingleService(params)
        },
        ...mapGetters(['getSingleService'])
    },
    methods: {
        ...mapActions(['fetchSingleService'])
    },

}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.section-hero {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
    height: 30vh;
    background-color: rgb(8, 39, 8);
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-top-right-radius: 80%;
    border-top: 50px solid #b1ad00;
    border-top-style: groove;
    width: 100%;
}

ul li {
    text-align: start;
    list-style-type: none;
    padding: 20px;

}

.get-in-touch-btn {
    background-color: #b1ad00;
}

.card {
    padding: 30px;
}

h6 {
    font-size: 1.2rem;
}
</style>