<template>
    <div 
        class="swiffy-slider slider-nav-round data-aos='fade-up' slider-nav-animation slider-nav-animation-fadein slider-item-first-visible" data-aos="fade-up">
        <ul class="slider-container" >
            <li class="slide-visible"  v-for="slider in sliders" :key="slider.title">
                <div class="card rounded-0 h-100">
                    <div class="row g-0 h-100">
                        <div class="col-md-6 col-xl-5 d-flex align-items-center p-2 p-md-3 p-xl-5">
                            <div class="card-body p-1 p-md-3 p-xl-5">
                                <p class="lead">{{ slider.subtitle}}</p>
                                <h2 class="card-title isTitle1">{{slider.title}}</h2>
                                <p class="card-text mt-3">{{ slider.description}}</p>
                                <p>Super simple setup using just markup and few powerful configuration options</p>                            
                            </div>
                        </div>
                        <div class="col-md-6 col-xl-7">
                            <img :src="slider.imagePath" class="card-img  d-md-block" loading="lazy"
                                style="height: 100%; object-fit: cover;" alt="...">
                        </div>
                    </div>
                </div>
            </li>
            
        </ul>

        <button type="button" class="slider-nav" aria-label="Go left"></button>
        <button type="button" class="slider-nav slider-nav-next" aria-label="Go left"></button>

        <div class="slider-indicators">
            <button class="active" aria-label="Go to slide"></button>
            <button aria-label="Go to slide"></button>
            <button aria-label="Go to slide"></button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    name: 'mainSlider',
   
    computed:{
        ...mapState({
            sliders: state => state.slider
        })
    },
    methods:{
            ...mapActions(['fetchSlider'])
    },
    mounted(){
        this.fetchSlider()
    }
   
}

</script>

