<template>
    <div>
        <div class="container-fluid section-title ">
            <h4 class="text-light fs-1">Our Services</h4>
        </div>
        <div class="container py-4 p-4 text-center services-section">
            <div class="row justify-contentcenter">
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 mb-3" v-for="service in services" :key="service.title" data-aos="fade-up">
                    <div class="card" >
                        <img class="images d-flex align-self-center" :src="service.imagePath" loading="lazy" :alt="service.title" >
                        <div class="card-body">
                            <h4>{{ service.title }}</h4>
                            <hr>
                            <p>{{ service.description }}</p>
                            <div>
                                <router-link :to="`/service/${service.title}`" class="btn service-read-more">Read More</router-link>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>

</template>


<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: 'servicesSection',

    computed: {
        ...mapState({
            services: state => state.services
        })
    },
    methods: {
        ...mapActions(['fetchServices'])
    },
    mounted() {
        this.fetchServices()
    }
}
</script>

<style scoped>
.images {
    max-width: 100%;
    width: 40%;
    height: auto;
    padding: 10px;
}

button {
    text-decoration: underline;
}

.card-body {
    font-size: 1rem;
    flex: 1;

}

.services-section {
    margin-top: -70px;

}

.section-title {
    text-align: center;
    padding: 70px;
    background-color: rgb(8, 39, 8);
}

.card {
    border-top: 5px solid #b1ad00;
    box-shadow: 0px 9px 30px rgba(255, 149, 5, 0.1);
    display: flex;
    flex-direction: column;
    height: 100%;
    
}

.card:hover {
    background-color: #b1ad00;
    cursor: pointer;
    color: white;

}
</style>