<template>
    <div class="container-fluid " :style="{
        backgroundImage: 'url(' + image + ')',
        backgroundBlendMode: 'multiply',
        backgroundColor: 'rgb(70, 70, 70)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    }">
    <div class="container" data-aos="fade-up">

  <div class="row justify-content-center">
    <div class="col-12 col-sm-10 col-md-8 text-center p-4">
      <ul class="bg-light px-4 py-3 rounded bulletingSection">
        <div class="container-fluid section-title ">
          <h4 class=" fs-1 isTitle1">Why CHoose Us</h4>
          <hr>
      </div>
        <li>
          <h6><span><i class="bi bi-check-circle-fill icon1"></i></span> Tailored Strategic Solutions</h6>
          <p class="desc">Customized consulting services designed to meet your specific business needs and challenges.</p>
        </li>
        <li>
          <h6><span><i class="bi bi-check-circle-fill icon1"></i></span> Continuous Improvement</h6>
          <p class="desc">Commitment to ongoing learning and adaptation to stay ahead in a rapidly evolving business landscape.</p>
        </li>
        <li>
          <h6><span><i class="bi bi-check-circle-fill icon1"></i></span> Leadership Development</h6>
          <p class="desc">Programs and coaching to develop effective leadership skills and drive organizational change.</p>
        </li>
        <li>
          <h6><span><i class="bi bi-check-circle-fill icon1"></i></span> Risk Management Strategies</h6>
          <p class="desc">Expertise in identifying and mitigating risks to safeguard business continuity and reputation.</p>
        </li>
        <li>
          <h6><span><i class="bi bi-check-circle-fill icon1"></i></span> Leadership Development</h6>
          <p class="desc">Programs and coaching to develop effective leadership skills and drive organizational change.</p>
        </li>
        <div>
          <router-link to="/contact" class="btn get-in-touch-btn w-100">Get In Touch</router-link>
        </div>
      </ul>
    </div>
    
  </div>
</div>


    </div>
</template>

<script>
export default {
    name: 'bulletingSection',
    data() {
        return {
            image: '../assets/images/papa.jpg'
        }
    }
}
</script>

<style scoped>
img {
    background-blend-mode: multiply;
    background-color: rgb(73, 71, 71);
}
ul li{
text-align: start;
list-style-type: none;
padding: 20px;

}

.desc{
  margin-left: 36px;
}
.bulletingSection{
  margin-top: 60px;
}
.get-in-touch-btn{
  background-color: #b1ad00;
}
</style>