<template>
    <mainSlider />
    <servicesSection />
    <bulletingSection />
</template>

<script>
import mainSlider from '../components/mainSlider.vue';
import servicesSection from '../components/servicesSection.vue'
import bulletingSection from '../components/bulletingSection.vue'

import { useMeta } from 'vue-meta'

export default {
    name: 'homePage',
    components: {
        mainSlider,
        servicesSection,
        bulletingSection
    },
    setup() {
        useMeta({
            title: 'Stan Consultancy - Your Trusted Business Partner',
            meta: [
                { name: 'description', content: 'Stan Consultancy provides expert business consulting services to help your business succeed. Contact us today!' },
                { property: 'og:title', content: 'Stan Consultancy - Your Trusted Business Partner' },
                { property: 'og:description', content: 'Stan Consultancy provides expert business consulting services to help your business succeed. Contact us today!' },
                { name: 'twitter:title', content: 'Stan Consultancy - Your Trusted Business Partner' },
                { name: 'twitter:description', content: 'Stan Consultancy provides expert business consulting services to help your business succeed. Contact us today!' }
            ]
        });
    }


}
</script>